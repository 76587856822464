import LoadingBar from "react-top-loading-bar"
import { useEffect, Suspense, useRef, useState } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { globalState, queryClient } from "config"
import { useRequest } from "hooks"
import { get, isEmpty } from "lodash"

import { Spinner } from "components"
import { Emitter, api } from "services"
import cache from "services/Storage"
import useClearCache from "hooks/useClearCache"

import AdminRoutes from "./Admin"
import GuestRoutes from "./Guest"

function Routes() {
  useClearCache()
  const loadingBar = useRef()
  const [isFetching, setIsFetching] = useState(false)
  const state = useQuery({
    queryKey: ["globalState"],
    queryFn: () => globalState,
    initialData: () => globalState,
    staleTime: Infinity,
  })

  const params = new URL(window.location).searchParams

  let profile
  try {
    profile = JSON.parse(sessionStorage.getItem("user"))
  } catch (e) {
    //
  }

  const getMe = useRequest({
    url: profile ? `users/getMe?id=${get(profile, "id")}` : "users/getMe",
    enabled: false,
    route: "",
    retry: 0,
    onSuccess: (data) => {
      const user = get(data, "data") || {}

      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        user,
        isAuth: true,
      }))
      setIsFetching(false)
      cache.set("user", user)
    },

    onError: () => {
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        isAuth: false,
      }))
      cache.remove("user")
      setIsFetching(false)
    },
  })

  useRequest({
    url: profile
      ? `users/${get(state.data, "user.id")}/storages?id=${get(profile, "id")}`
      : `users/${get(state.data, "user.id")}/storages`,
    route: "",
    enabled: !!get(state.data, "user.id"),
    retry: 0,
    onSuccess: (data) => {
      const storage = isEmpty(globalState.storage) ? get(data, "data[0]") : globalState.storage
      try {
        cache.set("storages", get(data, "data"))
        cache.set("storage", storage)
      } catch (e) {
        //
      }
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        storages: get(data, "data"),
        storage,
      }))
      setIsFetching(false)
    },

    onError: () => {
      queryClient.setQueryData(["globalState"], (old) => ({
        ...old,
        isAuth: false,
      }))
      cache.remove("user")
      setIsFetching(false)
    },
  })

  useEffect(() => {
    if (!cache.get("user") && state.data.isAuth) {
      setIsFetching(true)
      getMe.refetch()
    }
  }, [state.data.isAuth])

  useEffect(() => {
    const token = params.get("token")
    if (token) {
      sessionStorage.setItem("token", token)
    }
    api.interceptors.request.use((request) => {
      if (loadingBar.current) loadingBar.current.continuousStart()
      return request
    })

    api.interceptors.response.use((res) => {
      if (loadingBar.current) loadingBar.current.complete()
      return res
    })

    Emitter.on("REFETCH_PROFILE", () => {
      getMe.refetch()
    })
  }, [])

  return (
    <div className="app">
      {isFetching ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          {state.data.isAuth ? (
            <RouterProvider router={createBrowserRouter(AdminRoutes)} />
          ) : (
            <RouterProvider router={GuestRoutes} />
          )}
        </Suspense>
      )}
      <LoadingBar color="#6a6cf7" ref={loadingBar} shadow />
    </div>
  )
}

export default Routes
