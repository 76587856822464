import { useSearchParams } from "react-router-dom"
import qs from "qs"
import { keys, find } from "lodash"
import { time } from "services"

function useCustomSearchParams({ sessionKey } = {}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = qs.parse(sessionKey ? sessionStorage.getItem(sessionKey) : searchParams.toString())
  const objectKeys = keys(params)
  let type = "asc"
  const sortkey = find(objectKeys, (key) => key.includes("sort_"))
  const sortValue = searchParams.get(sortkey)
  if (sortValue === "asc") type = "-"
  if (sortValue === "desc") type = "+"
  const sort = sortkey
    ? {
        sort: sortkey.replace("sort_", type),
      }
    : {}

  let filter = {}
  const filterKeys = objectKeys.filter((key) => key.includes("_value") && !key.includes("m_"))
  filterKeys.forEach((key) => {
    filter[key.replace("_value", "")] = params[key]
  })

  const arrayFilter = {}
  const multifilterKeys = objectKeys.filter((key) => key.includes("m_") && key.includes("_value"))
  multifilterKeys.forEach((key, i) => {
    const objKey = key.replace("_value", "").replace("m_", "").replace(`${i}`, "")
    arrayFilter[objKey] = [...(arrayFilter[objKey] || []), params[key]]
  })
  filter = { ...filter, ...arrayFilter }

  const dateKeys = objectKeys.filter((key) => key.includes("date"))
  dateKeys.forEach((key) => {
    filter[key] = time.to(new Date(params[key] / 1), "YYYY-MM-DD")
  })
  if(params.search) filter.search = params.search
  return {
    searchParams,
    setSearchParams,
    sort,
    filter,
  }
}

export default useCustomSearchParams
