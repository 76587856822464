import { Suspense } from "react"
import { Outlet } from "react-router-dom"
import { Restore } from "Courier/components"

import ModalOrderView from "Pages/AttachCurier/ModalOrderView"
import ScannedProductInfo from "./ScannedProductInfo"

import "./styles.scss"
import "./packer-modal.scss"

function PackerLayout() {
  return (
    <div className="packer packer-layout">
      <div className="packer-wrapper">
        <Suspense>
          <Outlet />
        </Suspense>
      </div>
      <Suspense>
        <ScannedProductInfo />
      </Suspense>
      <Restore />
      <ModalOrderView />
    </div>
  )
}

export default PackerLayout
