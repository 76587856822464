import { Navigate, createBrowserRouter } from "react-router-dom"
import Pages from "./Pages"

const routes = [
  {
    path: "/signin",
    index: true,
    element: <Pages.Signin />,
  },
  {
    path: "/restore",
    element: <Pages.RestorePassword />,
  },
  {
    path: "*",
    element: <Navigate to="/signin" replace />,
  },
]

const GuestRoutes = createBrowserRouter(routes)

export default GuestRoutes
