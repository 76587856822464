import { lazy } from "react"
import { helpers } from "services"

import Fields from "./Fields"
import { Icons } from "./Icons"
import ModalContents from "./ModalContents"

const Tabs = helpers.withSuspense(lazy(() => import("./Tabs")))

const Modal = helpers.withSuspense(lazy(() => import("./Modal")))
const Delete = helpers.withSuspense(lazy(() => import("./Delete")))
const Filter = helpers.withSuspense(lazy(() => import("./Filter")))
const Spinner = helpers.withSuspense(lazy(() => import("./Spinner")))
const ListSort = helpers.withSuspense(lazy(() => import("./ListSort")))
const Paginate = helpers.withSuspense(lazy(() => import("./Paginate")))
const BoolenCol = helpers.withSuspense(lazy(() => import("./BoolenCol")))
const PageHeader = helpers.withSuspense(lazy(() => import("./PageHeader")))
const RechartPie = helpers.withSuspense(lazy(() => import("./RechartPie")))
const CustomTable = helpers.withSuspense(lazy(() => import("./CustomTable")))
const ErrorBoundary = helpers.withSuspense(lazy(() => import("./ErrorBoundary")))
const PageNavigation = helpers.withSuspense(lazy(() => import("./PageNavigation")))
const SelectModalList = helpers.withSuspense(lazy(() => import("./SelectModalList")))
const ColumnTitleSort = helpers.withSuspense(lazy(() => import("./ColumnTitleSort")))
const SelectSubCategories = helpers.withSuspense(lazy(() => import("./SelectSubCategories")))

export {
  Fields,
  Icons,
  Tabs,
  Modal,
  Delete,
  Filter,
  Spinner,
  ListSort,
  Paginate,
  BoolenCol,
  PageHeader,
  RechartPie,
  CustomTable,
  ErrorBoundary,
  ModalContents,
  PageNavigation,
  SelectModalList,
  ColumnTitleSort,
  SelectSubCategories,
}
