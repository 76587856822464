import { Icons, Modal } from "components"
import { useEffect, useState } from "react"
import "./styles.scss"
import { Emitter } from "services"

function ScannedProductInfo() {
  const [isOpen, setOpen] = useState()

  useEffect(() => {
    Emitter.on("OPEN_MODAL", () => {
      setOpen(true)
    })
  }, [])

  return (
    <Modal isOpen={isOpen} handleClose={() => setOpen(false)} className="packer__product-info">
      <div className="close-icon" onClick={() => setOpen()}>
        <Icons.XIcon />
      </div>
      <div className="row pt-30 pl-30 pr-30">
        <div className="col">
          <h3>Mahsulot</h3>
        </div>
        <div className="col end">
          <div className="status">
            <span className="circle"> </span>
            <p>Bekor qilingan</p>
          </div>
        </div>
      </div>
      <div className="title pl-30 pr-30">
        <div className="icon">
          <Icons.PackerCategory />
        </div>
        <p className="title__text">Apple iwatch ultra (lux copy)</p>
      </div>

      <div className="group1 mt-20 pl-30 pr-30">
        <div className="d-f space-between">
          <div className="left">
            <div className="icon icon__donate">
              <Icons.PackerDonate />
            </div>
            <p className="txt-1">Soni:</p>
          </div>
          <div className="right c-red txt-2">2ta</div>
        </div>
        <div className="d-f space-between mt-15">
          <div className="left">
            <div className="icon icon__coin">
              <Icons.PackerCoin />
            </div>
            <p className="txt-1">Narxi:</p>
          </div>
          <div className="right txt-2">234,000 uzs</div>
        </div>
        <div className="d-f space-between mt-20">
          <div className="left">
            <div className="user">
              <img src="https://picsum.photos/200" alt="" />
              <div className="info">
                <h4>Kamalov Davron</h4>
                <p>Xaridor</p>
              </div>
            </div>
          </div>
          <div className="right c-red">
            <div className="user">
              <img src="https://picsum.photos/200" alt="" />
              <div className="info">
                <h4>Kamalov Davron</h4>
                <p>Xaridor</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="group2 pl-30 pr-30 pb-30">
        <div className="d-f space-between mt-20">
          <div className="left">
            <div className="user">
              <img src="https://picsum.photos/200" alt="" />
              <div className="info">
                <h4>Kamalov Davron</h4>
                <p>Xaridor</p>
              </div>
            </div>
          </div>
          <div className="right c-red">
            <div className="user">
              <div className="icon">ID</div>
              <div className="info">
                <h4>79900</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="d-f space-between mt-20">
          <div className="left">
            <div className="user">
              <div className="icon">
                <Icons.LocationMarker />
              </div>
              <div className="info">
                <h5>Toshkent shah, Uchtepa tum, Foziltepa ko‘ch</h5>
              </div>
            </div>
          </div>
        </div>
        <div className="d-f space-between mt-20">
          <div className="left">
            <div className="user">
              <div className="icon">
                <Icons.Clock2 />
              </div>
              <div className="info">
                <h4>12.03.2023</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ScannedProductInfo
